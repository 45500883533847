@font-face {
    font-family: 'Dela Gothic';
    src: url(../public/font/Dela_Gothic_One/DelaGothicOne-Regular.ttf);
}
@font-face {
    font-family: 'Manrope';
    src: url(../public/font/Manrope/Manrope-VariableFont_wght.ttf);
}
@font-face {
    font-family: 'Zen Old';
    src: url(../public/font/Zen_Old_Mincho/ZenOldMincho-Black.ttf);
}

body, div, h1, h2, h3, h4, h5, h6, p, ol, ul, blockquote, figure{
    margin:0;
    padding:0;
    font-weight: normal;
    font-size: unset;
    cursor: default;
    font-family: 'Manrope' , sans-serif;
}

body{
    position: relative;
    max-width: 1920px;
    margin:0 auto;
    -webkit-text-size-adjust: 100%;
    background-color: #fff;
}
a{
    text-decoration: none;
    color: inherit;
    cursor:pointer;
}
li{
    list-style-type: none;
}
html {
    scroll-behavior: smooth;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
*, *:before, *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}
img{
    width:100%;
    object-fit: cover;
}
.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.flex.col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.flex.centered{
    align-items: center;
}
.flex.space{
    justify-content: space-between;
}
header{
    height:100vh;
    position: relative;
}
.shiny-text {
    font-size: 160px;
    background: linear-gradient(90deg, #D4AF37, #FFF9C4, #D4AF37);
    background-size: 200% 200%;
    color: transparent;
    background-clip: text;
    position: absolute;
    right: 40px;
    top: 40px;
    text-align: right;
    width: 60%;
    min-width: 1120px;
}
.shiny-text h1{
    font-family: 'Dela Gothic',sans-serif;
}
.shiny-text:hover{
    animation: shiny 3s linear infinite;
}
@keyframes shiny {
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
}

.main-image{
    width:50%;
}
.main-image img{
    height: 100%;
}
.shiny-text img{
    width: 200px;
    margin-right: 50px;
}
#inviteDay, .hour-wrapper span{
    font-size:48px;
    color:#D4AF37;
    font-family: 'Zen Old',sans-serif;
}
.left-col{
    font-size:32px;
    color: #977301;
    width: 50%;
}
.right-col span{
    font-size:32px;
    color: #977301;
}
.right-col span:first-of-type{
    font-size:40px;
    color:#D4AF37;
    font-family: 'Zen Old',sans-serif;
    padding-bottom:15px;
}
.right-col{
    text-align: center;
}
.parents{
    padding-bottom:30px;
}
.invite{
    max-width: 1500px;
    margin: 0 auto;
    padding: 180px 30px 100px;
}
#inviteDate{
    padding: 0 15px 20px;
    font-size: 40px;
    text-align: right;
}
#inviteDay{
    padding-left: 80px;
}
#inviteTitle{
    padding-bottom: 30px;
    text-align: center;
}
.hour-wrapper{
    justify-content: center;
}
.hour-wrapper span{
    padding-right:12px;
}
.hour-wrapper p{
    font-size:40px;
    line-height: 40px;
}
.heart-center img{
    width:150px;   
}
.heart-center {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
.heart-center.first{
    justify-content: end;
}

.heart-center.animate {
    opacity: 1;
    transform: scale(1);
}

.heart-center.animate .divpath {
    stroke-dasharray: 1000; 
    stroke-dashoffset: 0;
    animation: heartDash 5s linear forwards;
}

@keyframes heartDash {
    from {
        stroke-dashoffset: 1000; 
    }
    to {
        stroke-dashoffset: 0; 
    }
}
.heart-center{
    text-align: center;
}
.section-title{
    text-align: center;
}
.section-title h2{
    font-family: 'Zen Old',sans-serif;
    font-size: 64px;
    color:#977301;
}
.address{
    width:50%;
    text-align: center;
}
.map{
    width:50%;
}
.map iframe{
    aspect-ratio: 1.2;
    width:100%;
}
.address h3{
    color:#D4AF37;
    font-size:32px;
    font-weight: 700;
}
.address h4{
    color:#977301;
    font-size:32px;
    padding:20px;
}
.location{
    padding:50px;
}
.venue h4 strong{
    color:#D4AF37;
}
.venue{
    flex-direction: row-reverse;
}
.count{
    justify-content: center;
    padding:100px;
    text-align: center;
}
.count p{
    font-size: 140px;
    padding: 0 45px;
    background: linear-gradient(90deg, #D4AF37, #FFF9C4, #D4AF37);
    font-family: 'Dela Gothic',sans-serif;
    color: transparent;
    background-clip: text;
}
.count span{
    font-size:25px;
    color:#977301;
}
.countdown a{
    text-align: center;
    font-size: 24px;
    padding: 10px;
    background-color: #d4af3798;
    color: #977301;
    display: block;
    width: 200px;
    margin: 0 auto 70px;
}
.baby img{
    aspect-ratio: 0.8;
}
.childSwiper{
    padding: 50px 0;
}
.phone-numbers{
    padding: 100px;
}
.phone-numbers span{
    display: block;
    padding-bottom:10px;
}
.vag , .dim{
    width: 50%;
    text-align: center;
}
.phone-numbers a , .phone-numbers span{
    font-size:25px;
    color:#977301;
}
.copyrights{
    text-align: center;
    color:#574200;
    padding: 0 50px;
}
.copyrights p{
    padding: 20px 0;
    border-top:4px solid #d4af37bb;
}
.vag{
    border-right: 1px solid #D4AF37;
}
@media screen and (max-width:1440px){
    .shiny-text{
        font-size: 110px;
        min-width: 700px;
    }
}

@media screen and (max-width:1200px){
    .invite{
        padding: 80px 50px 80px 30px;
    }
    .left-col{
        font-size:28px;
    }
    #inviteDay, .hour-wrapper span{
        font-size: 35px;
        padding-bottom: 6px;
    }
    #inviteDate {
        padding: 15px 15px 20px;
        font-size: 28px;
    }
    .hour-wrapper p{
        font-size:28px;
    }
    .right-col span {
        font-size: 28px;
    }
    .right-col span:first-of-type{
        font-size:35px;
    }
    .section-title h2{
        font-size:50px;
    }
    .address h4{
        font-size:28px;
    }
    .address h3{
        font-size:28px;
    }
    .count p {
        font-size: 100px;
    }
}
@media screen and (max-width: 768px) {
    .shiny-text {
        font-size: 75px;
        min-width: 530px;
        right:20px;
    }
    .shiny-text img {
        width: 110px;
        margin-right: 0;
    }
    .hour-wrapper p {
        font-size: 22px;
    }
    .left-col {
        font-size: 22px;
    }
    #inviteDay, .hour-wrapper span {
        font-size: 28px;
    }
    .hour-wrapper p , #inviteDate{
        font-size: 22px;
    }
    .right-col span:first-of-type{
        font-size:28px;
    }
    .right-col span{
        font-size:22px;
    }
    .heart-center img{
        width:110px;
    }
    .address h4, .address h3 {
        font-size: 22px;
    }
    .location {
        padding: 50px 0;
    }
    .count p {
        font-size: 60px;
        padding: 0 30px;
    }
    .count span {
        font-size: 18px;
    }
    .countdown a{
        font-size: 18px;    
        width: 170px;
    }
    .phone-numbers a, .phone-numbers span {
        font-size: 22px;
    }
    .copyrights{
        padding: 0 20px;
    }
    .main-image{
        width:70%;
    }
    #inviteDay {
        padding-left: 20px;
    }
    .section-title h2 {
        font-size: 40px;
    }
}
@media screen and (max-width:500px){
    .shiny-text{
        min-width: unset;
        top: unset;
        bottom: 0;
        width:100%;
        font-size: 52px;
        max-width: 310px;
    }
    .main-image{
        height:65%;
        width:100%
    }
    .invite{
        padding: 50px 20px 80px 20px;
        flex-direction: column;
    }
    .left-col ,.right-col{
        width:100%;
    }
    #inviteDay{
        width: 100%;
        display: block;
        text-align: center;
        padding: 0;
    }
    #inviteDate{
        text-align:center;
    }
    .right-col {
        padding-top: 35px;
    }
    .parents{
        text-align: left;
    }
    .godm{
        text-align: right;
    }
    .church{
        flex-direction: column;
    }
    .address , .map{
        width:100%;
    }
    .count{
        flex-wrap: wrap;
        padding:55px 0 20px;
    }
    .count > div{
        width:100%;
        text-align: left;
        padding-bottom: 30px;
    }
    .countdown > div{
        position: relative;
    }
    .count p{
        padding:0;
        font-size: 100px;
        line-height: 95px;
    }
    .section-title h2 {
        font-size: 35px;
    }
    .count span{
        padding-left: 10px;
    }
    .countdown a{
        margin: unset;
        margin-left: auto;
        position: absolute;
        bottom: 50px;
        right: 0;
    }
    .phone-numbers {
        padding: 20px;
        flex-direction: column;

    }
    .vag, .dim{
        width: 100%;
        padding: 20px 0;
    }
    .vag{
        border-bottom: 1px solid #D4AF37;
        border-right:unset;
    }
    .shiny-text{
        animation: shiny 3s linear infinite;
    }
}
